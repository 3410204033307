// import slick from "slick-carousel";
let iccClass = 'ao-intro-columned-content';

/**
 * initializeBlock
 *
 * All JS to load on the block page goes here.
 *
 * @date    15/4/19
 * @since   1.0.0
 *
 * @param   object $block The block jQuery element.
 * @param   object attributes The block attributes (only available when editing).
 * @return  void
 */
var initializeBlock = function($block, $attributes) {

	let iccToggle = '.ao-icc--single--content--toggle';
	let iccEllipsis = '.ao-icc--single--content--ellipsis';
	let iccReveal = '.ao-icc--single--content--reveal';

	$(iccToggle).on('click',function(){
		$(this).toggleClass('active');
		$(this).siblings(iccEllipsis).toggleClass('active');
		$(this).siblings(iccReveal).slideToggle();
	});

};


// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + iccClass).each(function() {
		initializeBlock($(this), false);
	});
});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=intro-columned-content", initializeBlock);
}